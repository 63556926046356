import { Box, Collapse, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTheme } from "@/theme/ThemeContext";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { ServerStatusType } from "@/store/toolsSportCounts";
import { useAppSelector } from "@/store/store";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslations } from "next-intl";
import { PAGES } from "./common";
import NavItem from "./NavItemDesktop";
import ResourceIcon from "../Icons/ResourceIcon";
import SettingsIcon from "../Icons/SettingsIcon";

const NavigationPanel = React.forwardRef(({ hideBorder }: { hideBorder?: boolean }, ref) => {
  const theme = useTheme();
  const [isCollapsed, toggleIsCollapsed] = useUserSettingsContextSelector((ctx) => [ctx.isCollapsed, ctx.toggleIsCollapsed]);
  const counts = useAppSelector((state) => state.toolsSportCountsReducer);

  const getCount = useCallback(
    (countKey: string | undefined) => {
      if (!countKey) return "";
      const selectedCounts = counts[countKey as keyof ServerStatusType] as number[];
      const sum = selectedCounts.reduce((prev, cur) => prev + cur, 0);
      if (sum < 1000) return sum.toString();
      return `${(sum / 1000).toFixed(1)}K`;
    },
    [counts],
  );

  const t = useTranslations("common");

  return (
    <Box
      position="relative"
      px={1.5}
      pt={1}
      zIndex={3}
      sx={{
        display: { md: "flex", xs: "none" },
        borderRight: hideBorder ? undefined : `1px solid ${theme.palette.divider}`,
        transition: "width 0.5s ease",
      }}
      ref={ref}
    >
      <Collapse
        orientation="horizontal"
        in={!isCollapsed}
        collapsedSize={40}
        timeout={500}
        sx={{ transition: "width 0.5s ease" }}
      >
        <Stack alignItems="start" direction="column" height="max-content" minWidth={200} maxWidth={260} gap={1}>
          <IconButton
            onClick={toggleIsCollapsed}
            sx={{ width: 40, height: 40, marginLeft: isCollapsed ? undefined : "auto", color: "text.secondary" }}
            disableFocusRipple
            disableRipple
          >
            {isCollapsed ? <ArrowForwardIosIcon sx={{ fontSize: 20 }} /> : <ArrowBackIosNewIcon sx={{ fontSize: 20 }} />}
          </IconButton>
          {PAGES.map((page) => (
            <NavItem
              key={page.title}
              href={page.route}
              icon={page.icon}
              isCollapsed={isCollapsed}
              disableFocusRipple
              disableRipple
            >
              <Typography variant={isCollapsed ? "label" : "body3"} sx={{ position: "relative" }} fontWeight="inherit">
                {t(page.title)}
                <Typography variant="supScript" component="sup" color={isCollapsed ? undefined : "text.secondary"}>
                  &nbsp;{getCount(page.countKey)}
                </Typography>
              </Typography>
            </NavItem>
          ))}
          <Divider
            sx={{
              borderColor: theme.palette.primary.main,
              width: "100%",
              my: 1,
            }}
          />
          <NavItem href="/settings" isCollapsed={isCollapsed} icon={<SettingsIcon />}>
            <Typography variant={isCollapsed ? "label" : "body3"} sx={{ position: "relative" }} fontWeight="inherit">
              {t("settings")}
            </Typography>
          </NavItem>
          <NavItem href="/resources" isCollapsed={isCollapsed} icon={<ResourceIcon />}>
            <Typography variant={isCollapsed ? "label" : "body3"} sx={{ position: "relative" }} fontWeight="inherit">
              {t("resources")}
            </Typography>
          </NavItem>
          <Divider
            sx={{
              borderColor: theme.palette.primary.main,
              width: "100%",
              my: 1,
            }}
          />
        </Stack>
      </Collapse>
    </Box>
  );
});

export default NavigationPanel;
